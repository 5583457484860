import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { BACKEND_URL, FEDAPAY_PUBLIC_KEY } from "../config";
import { useTranslation } from 'react-i18next';


const Fedapay = ({item, options}) => {
    const flag = useRef(false);
    const [widget, setWidget] = useState();
     const { t, i18n } = useTranslation();
    
    useEffect(() => {
        const initialize = async () => {
            const id_currency = options['id_currency'];
            let success_url = options['success_url'];
            const description = options['description'] ?? `Paiement pour ${item['name']} via Fedapay`;
            
            let amountXof = Math.round(item['price']);
            if(id_currency !== 3)
            {
                await axios.get(`${BACKEND_URL}payment/conversion/${id_currency}`)
                .then(response => amountXof = Math.round(item['price'] * response.data.currency.value_to_xof))
            }
            const FedaPayApi = window['FedaPay'];
            if(!FedaPayApi)
                throw new Error('Impossible de charger Fedapay')
                
            const init = FedaPayApi.init({
                public_key: FEDAPAY_PUBLIC_KEY,
                transaction: {
                    amount: amountXof,
                    description: description
                },
                customer: {
                email: options.customer.email,
                lastname: options.customer.last_name,
                firstname: options.customer.first_name,
                phone_number: {
                    number: options.customer.phone,
                    country: options.customer.code_country.toUpperCase()
                }
                },
                onComplete: function (data) {
                    if (data.reason === 'CHECKOUT COMPLETE' && data.transaction.status === 'approved') {
                        let tab = success_url.split('?');
                        success_url= tab[0];
                        let query= tab[1] ?? '';
                        if(query.length >0) query +='&';
                        
                        query += 'provider=2&paymentId=' +data.transaction.id +'&id_currency='+ id_currency;
                        window.location = success_url +'?' + query;
                    }else{
                        console.log(data);
                    }
                }
            });
            setWidget(init)
        }
        if(flag.current === false)
        {
            initialize()
        }

        return () => flag.current = true
    }, [])

    const handle = () => {
        if(widget)
            widget.open()
    }
    
    return (
        <button onClick={() => handle() } className='text-white bg-[#D19E06] hover:bg-black px-3 py-1 rounded my-4 text-center w-3/4 md:w-1/2 mx-auto block'>
               {t("Mobile Money")}
        </button>
    );
};

export default Fedapay;