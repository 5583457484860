import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { lime } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import plat1 from '../../ASSETS/Image/neutre.jpg';
import { IoAddCircleOutline } from "react-icons/io5";
import { home } from '../../API/Home/home';
import { CartContext } from '../../CartContext'; // Modifie le chemin selon ta structure
import { logo } from '../../API/Logo/logo';
import { useTranslation } from 'react-i18next';

export default function Food() {
  const theme = createTheme({
    palette: {
      secondary: {
        main: lime[900],
      },
    },
  });
    
  const [value, setValue] = useState('1');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [device,setDevice] = useState();
  const { addToCart,cart,setCart} = useContext(CartContext); // Utilise le contexte ici
  
  const formatNumberWithSpaces = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
  };

  useEffect(() => {
    home().then((response) => {
      if(response)
      {
        setProducts(response.data);
        setFilteredProducts(response.data);  // Initialize filteredProducts with all products
        setDevice(response.device);
      }
    });
  }, []);
  
  useEffect(() => {
    if (searchTerm === '') {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((prod) =>
        prod.Names ? prod.Names.toLowerCase().includes(searchTerm) : false
      );
      setFilteredProducts(filtered);
    }
  }, [searchTerm, products]);

  const [logos,setLogos] = useState('');
      useEffect(()=>{
             logo().then((response)=>{
              setLogos(response.logo64)
             }).catch((error)=>{
              console.log("Erreur du chargement ",error)
             })
      },[])

      const uniqueCategories = Array.from(
        new Set(
          products.map(prod => JSON.stringify({
            fr: prod.categorie,
            en: prod.en_category
          }))
        )
      ).map(item => JSON.parse(item));
  
  const searchParams = new URLSearchParams(window.location.search);
  const idItem = searchParams.get('id');
  //const item = filteredProducts.find((filter) => filter.id === parseInt(idItem));
 
  useEffect(() => {
    if (idItem) {
      let panier = JSON.parse(localStorage.getItem('panier')) || [];
      const item = filteredProducts.find((filter) => filter.id === parseInt(idItem));
  
      if (item) {
        const existItem = panier.find((prodId) => prodId.id === item.id);
        if (existItem) {
          existItem.quantity += 1;
        } else {
          item.quantity = 1;
          panier.push(item);
        }
        localStorage.setItem('panier', JSON.stringify(panier));
        setCart(panier);
      } else {
        console.log("Produit non trouvé dans filteredProducts");
      }
    }
  }, [idItem, filteredProducts]);  

useEffect(()=>{
  let panier = JSON.parse(localStorage.getItem('panier')) || [];
  if(panier && panier.length >0)
  {
    setCart(panier)
  }
},[])   

const { t, i18n } = useTranslation();
const langue = i18n.language === 'fr'? 'fr_FR' : 'en_US'


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          {/* Navbar */}
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className='flex s:flex-row xs:flex-col s:h-20 xs:h-[210px] s:items-center s:space-x-8 xs:space-y-8 s:space-y-0 text-xl bg-white shadow-md px-6 z-10 fixed w-2/3'>
            <div className='text-3xl'>
               <img className="w-40 h-10 object-cover"  src={`data:image/png;base64,${logos}`} alt="Logo"/>
            </div>
            <TabList
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label={t('Tout')} value="1" />
              {uniqueCategories.map((category, index) => (
                <Tab key={index} label={langue === 'fr_FR' ? category.fr : category.en } value={langue === 'fr_FR' ? category.fr : category.en} />
              ))}
            </TabList>
            <input type='search' onChange={handleSearch} className='bg-[#EEEEEE] h-10 px-3 outline-none text-base' placeholder={t('Entrer un mot clé')}/>
           
          </Box>

          {/* Component */}
          <div className='xs:pt-60 s:pt-28 grid md:grid-cols-3 xs:grid-cols-1 s:grid-cols-2 gap-y-10'>
            {filteredProducts.map((prod, index) => (
              <React.Fragment key={index}>
                <TabPanel value={langue === 'fr_FR' ? prod.categorie : prod.en_category }>
                  <div className='bg-white w-[240px] h-[350px] py-4 px-3 rounded shadow-md'>
                    <img src={prod?.img ? `data:image/png;base64,${prod.img}` : plat1} alt='plat1' className='w-[210px] h-[150px]' />
                    <div className='text-white bg-[#D19E06] w-[100px] text-center relative bottom-6 left-28'>
                         {langue === 'fr_FR' ? prod.categorie : prod.en_category}
                    </div>
                    <div className='text-lg font-medium pt-3 text-[#D19E06]'>{langue === 'fr_FR' ? prod.title : prod.Names}</div>
                    <div>
                       {formatNumberWithSpaces(prod.price)} {device.sign}
                    </div>
                  
                    <button
                      className='flex flex-row items-center justify-center space-x-2 bg-black text-white w-[210px] h-10 px-3 mt-4 rounded'
                      onClick={() => addToCart(prod)} // Utilise la fonction addToCart ici
                     //onClick={()=>handleScan(prod.Items_Numbers)}
                    >
                      <IoAddCircleOutline className='text-xl' />
                      <p>{t("Ajouter")}</p>
                    </button>
                  </div>
                </TabPanel>
              </React.Fragment>
            ))}
           {filteredProducts.map((prod, index) => (
             <React.Fragment key={index}>
              <TabPanel value="1">
                <div className="bg-white w-[240px] h-[350px] py-4 px-3 rounded shadow-md flex flex-col justify-between">
                  <div className="relative">
                    {/* Image */}
                    <img 
                      src={prod?.img ? `data:image/png;base64,${prod.img}` : plat1} 
                      alt="plat1" 
                      className="w-[210px] h-[150px] object-cover"
                    />
                      {/* Scanner QR code  */}
                          {/*
                              <div className="absolute top-0 left-0 w-full h-full bg-gray-300 bg-opacity-10 z-10 flex justify-center items-center">
                                 <QRCodeCanvas value={`localhost:3000/?id=${encodeURIComponent(prod.id.toString())}`} size={150} />
                              </div>
                          */} 
                      
                    {/* Catégorie */}
                    <div className="text-white bg-[#D19E06] w-[100px] text-center absolute bottom-6 left-28">
                      {langue === 'fr_FR' ? prod.categorie : prod.en_category}
                    </div>
            
                    {/* Nom du produit */}
                    <div className="text-lg font-medium pt-3 text-[#D19E06]">{langue === 'fr_FR' ? prod.title : prod.Names}</div>
            
                    {/* Prix */}
                    <div>{formatNumberWithSpaces(prod.price)} {device.sign}</div>
                  </div>
            
                  {/* Bouton d'ajout au panier */}
                  <button
                    className="flex flex-row items-center justify-center space-x-2 bg-black text-white w-[210px] h-10 px-3 mt-4 rounded"
                    onClick={() => addToCart(prod)}
                  >
                    <IoAddCircleOutline className="text-xl" />
                    <p>{t('Ajouter')}</p>
                  </button>
                </div>
              </TabPanel>
            </React.Fragment>          
            ))}

          </div>
        </TabContext>
      </Box>
    </ThemeProvider>
  );
}
