let SaveToken= (token)=>{
    localStorage.setItem('token',token);
}
let getToken= ()=>{
   return  localStorage.getItem('token');
}
let SaveId = (id)=>{
    localStorage.setItem('id',id)
}
let getId = ()=>{

    let id = localStorage.getItem('id')
    return id;
}
let logout = ()=>{
    
    localStorage.removeItem('token');
}

let isLogged = ()=>{
    let token = localStorage.getItem('token')
    return !!token;
}

export const accountService ={
    SaveToken,logout,isLogged,SaveId,getId,getToken
}