import Loading from '../../COMPONENTS/Loading';
import React, { useState, useEffect } from 'react';
import  Header  from "../../COMPONENTS/Header";
import { toast, ToastContainer } from 'react-toastify';
import { logo } from "../../API/Logo/logo";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { factures } from "../../API/factures/factures";
import { FaCalendarAlt, FaDownload, FaFileInvoiceDollar, FaPlus, FaSyncAlt } from 'react-icons/fa';
import { FaArrowLeft, FaArrowRight, FaPaperPlane } from "react-icons/fa6";
import { MdCheckCircle, MdOutlineCancel } from "react-icons/md";
import axiosInstance from '../../_utils/axiosConfig';
import { home } from '../../API/Home/home';
import { useTranslation } from 'react-i18next';


function Document() {
  const [loading, setLoading] = useState(true);

      useEffect(() => {
        const timer = setTimeout(() => {
          // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
          setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes
    
        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
      }, []);
      
  const [search,setSearch] = useState('');
  const [startDate,setStartDate] = useState('');
  const [startTime,setStartTime] = useState('');
  const [facture,setFacture] = useState(true);
  const [reservation,setReservation] = useState(false)
  const [reservations,setReservations] = useState([]);
  const [openIndex, setOpenIndex] = useState(null); // Stocker l'index du menu ouvert
  const [openIndexe, setOpenIndexe] = useState(null); // Stocker l'index du menu ouvert
  const [facturations,setFacturations] = useState([]);
  const [bgColorf, setBgColorf] = useState('bg-[rgb(32,147,19)]');
  const [bgColorr, setBgColorr] = useState('bg-[#C2C2C2]');
  const [currentPage,setCurrentPage] = useState(1);
  const [totalPage,setTotalPage] = useState(1);
  const [totalPageReservation,setTotalPageReservation] = useState(1);
  const [filteredReservations,setFilteredReservations] = useState([]);
  const [filteredFacturations,setFilteredFacturations] = useState([]);
  const [details,setDetails] = useState([]);
  const [totalTaxe1,setTotalTaxe1] = useState(0);
  const [totalTaxe2,setTotalTaxe2] = useState(0);
  const [totalTaxe3,setTotalTaxe3] = useState(0);
  const [device,setDevice] = useState({});
  const [prixu,setPrixu] = useState(0);
  const [qt,setQt] = useState(0);
  const [mht,setMht] = useState(0);
  const [mt,setMt] = useState(0);
  const [det,setDet] = useState(false)
  const [openMode,setOpenMode] = useState(false);
  const [opens,setOpens] = useState([]);
  const [isModalRecu,setIsModalRecu] = useState(false);
  const [recu,setRecu] = useState([]);
  const [identifiantSeller,setIdentifiantSeller] = useState({});
  const [identifiantCustmer,setIdentifiantCustmer] = useState({});
  const [info,setInfo] = useState({});
  const itemsPerPage = 4;
  const bgCommandeDelivre = "bg-green-300";
  const bgCommandeNoDelivre = "bg-amber-200";


  useEffect(() => {
    home().then((response) => {
      if(response)
      {
        setDevice(response.device);
        const id = JSON.stringify(response.device.id_currency);  // Utiliser response.device.id directement
        localStorage.setItem('idCurrency', id);
      }
    });
  }, []);


  useEffect(() => {
    axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/getReservationDocument`)
      .then((response) => {
        if (response.data.documents) {
          setReservations(response.data.documents);
          setTotalPageReservation(Math.ceil(response.data.documents.length/itemsPerPage))
        } else {
          console.log("Erreur");
        }
      })
      .catch((error) => {
        console.log("Erreur lors de la récupération", error);
      });
  }, []);

  const handleNext = () => {
    if (currentPage < totalPageReservation) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleNextFact = () => {
    if (currentPage < totalPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  

 const handleReservationChange = (event)=>{
    const seachValue = event.target.value.toLowerCase();
    setSearch(seachValue)
 }

  const [logos,setLogos] = useState('');
      useEffect(()=>{
             logo().then((response)=>{
              setLogos(response.logo64)
             }).catch((error)=>{
              console.log("Erreur du chargement ",error)
             })
      },[])
  const OnFactureClick = ()=>{
    setFacture(true)
    setReservation(false)
    setBgColorf('bg-[rgb(32,147,19)]')
    setBgColorr('bg-[#C2C2C2]')
  }

  const OnReservationClick = ()=>{
    setFacture(false)
    setReservation(true)
    setBgColorf('bg-[#C2C2C2]')
    setBgColorr('bg-[rgb(32,147,19)]')
  }
  
  useEffect(() => {
    if (search || startDate || startTime) {
      // Transformer les réservations en séparant la date et l'heure
      const transformedReservations = reservations.map((reserve) => {
        const [date, time] = reserve.starting_date.split(' : ');
        return { ...reserve, starting_date: date, starting_time: time };
      });
  
      // Fonction pour formater la date
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        });
      };
  
      // Appliquer les filtres
      const filtered = transformedReservations.filter((reserve) => {
        const matchDate = !startDate || (reserve.starting_date && reserve.starting_date === formatDate(startDate));
        const matchTime = !startTime || (reserve.starting_time && reserve.starting_time.includes(startTime));
        const matchSearch = !search ||
          (reserve.customer_name && reserve.customer_name.toLowerCase().includes(search.toLowerCase())) ||
          (reserve.customer_last_name && reserve.customer_last_name.toLowerCase().includes(search.toLowerCase())) ||
          (reserve.group_reservation && reserve.group_reservation.toLowerCase().includes(search.toLowerCase()));
        return matchDate && matchSearch && matchTime;
      });
     
      setFilteredReservations(filtered);
      setTotalPageReservation(Math.ceil(filtered.length/itemsPerPage))

      const filteredfacture = facturations.filter((fact)=>{
        const matchDate = !startDate || (fact.Dates && fact.Dates === formatDate(startDate));
               const matchSearch = !search ||
                 (fact.Customers && fact.Customers.toLowerCase().includes(search.toLowerCase())) ||
                (fact.invoice_number && fact.invoice_number.toLowerCase().includes(search.toLowerCase()));
              return matchDate && matchSearch;
      });
      setTotalPage(Math.ceil(filteredfacture.length / itemsPerPage));
        //  const currentFact = filteredfacture.slice(indexOfFirstItem, indexOfLastItem);
          setFilteredFacturations(filteredfacture);
         
    } 
  }, [search, startDate, startTime, currentPage, reservations,facturations]);
 
  
  useEffect(() => {
    const fetchFacturation = async () => {
      try {
        const response = await factures(currentPage);
        //console.log(response);
    
        // Conversion en tableau si nécessaire
        let facturesArray = Array.isArray(response.factures)
          ? response.factures
          : Object.values(response.factures); // ou Array.from(response.factures)
    
        setFacturations(facturesArray);
        setTotalPage(Math.ceil(facturesArray.length / itemsPerPage));
      } catch (error) {
        console.log("Erreur lors de la récupération :", error);
      }
    };    
    fetchFacturation();
  }, [currentPage]); 
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = reservations.slice(indexOfFirstItem, indexOfLastItem);
  const currentItem = facturations.slice(indexOfFirstItem, indexOfLastItem);

  const indexOfLastItemFilteredFacturation = currentPage * itemsPerPage;
  const indexOfFirstItemFilteredFacturation = indexOfLastItemFilteredFacturation - itemsPerPage;
  const itemsOfFilteredFacturation = filteredFacturations.slice(indexOfFirstItemFilteredFacturation,indexOfLastItemFilteredFacturation)

  const indexOfLastItemFilteredReservation = currentPage * itemsPerPage;
  const indexOfFirstItemFilteredReservation = indexOfLastItemFilteredReservation - itemsPerPage;
  const itemsOfFilteredReservation = filteredReservations.slice( indexOfFirstItemFilteredReservation,indexOfLastItemFilteredReservation)


  const toggleMenu = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Fermer le menu si c'est déjà ouvert
    } else {
      setOpenIndex(index); // Ouvrir le menu pour cet index
    }
  };

  const toggleMenue = (indexe) => {
    if (openIndexe === indexe) {
      setOpenIndexe(null); // Fermer le menu si c'est déjà ouvert
    } else {
      setOpenIndexe(indexe); // Ouvrir le menu pour cet index
    }
  };
  
  const host = window.location.hostname;
  const handleDownloadFacture = (factureNoInvoice) => {
    const id = localStorage.getItem('id');
    const iddb = localStorage.getItem('iddb')
      axiosInstance({
        url: `${process.env.REACT_APP_BASE_URL}/api/facture/download/${factureNoInvoice}`,
        method:'GET',
        responseType:'blob',
        params:{
                   id,
                   iddb
                },
        headers:{
          'Content-Type':'application/json',
          'X-Host': host
        },
        host:host
      })
    .then((response) => {
        // Créer un objet URL à partir du blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        
        // Créer un lien pour le téléchargement
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `facture_${factureNoInvoice}.pdf`); // Utiliser le numéro de facture dans le nom du fichier
        
        // Ajouter le lien au DOM, simuler un clic et retirer le lien
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Nettoyage du DOM
        
        // Libérer l'URL blob
        window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
        console.error('Erreur lors du téléchargement :', error);
        toast.error("Une erreur est survenue lors du téléchargement de la facture."); // Afficher un message d'erreur à l'utilisateur
    });
};

  const handeDowloadReservation  = (idreservation)=>{
    const id = localStorage.getItem('id');
    const iddb = localStorage.getItem('iddb')
      axiosInstance({
        url: `${process.env.REACT_APP_BASE_URL}/api/reservation/dowload/${idreservation}`,
        method:'GET',
        responseType:'blob',
        params:{
                 id,
                 iddb
              },
        headers:{
          'Content-Type':'application/json',
          'X-Host': host
        }
      }).then((response)=>{
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download','reservation.pdf');
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
      }).catch((error)=>{
        console.log("Erreur lors du téléchargement de la reservation ",error)
      })
  };

  const handleMailFacturSend = async (factureNoInvoice) => {
    const id = localStorage.getItem('id');
    const iddb = localStorage.getItem('iddb')

    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/getFactureMail/mail/${factureNoInvoice}`, {
            params: { 
                        id,
                        iddb
                     }
        });

        if (response.data.status === 200) {
            toast.success(response.data.message);
        } else {
            toast.error("Une erreur est survenue lors de l'envoi de l'e-mail.");
        }
    } catch (error) {
        console.error("Erreur lors de l'envoi :", error);
        toast.error("Erreur lors de l'envoi de l'e-mail. Veuillez réessayer.");
    }
};

 const handleMailReservationSend = async (idreservation) => {
  const id = localStorage.getItem('id');  
  const iddb = localStorage.getItem('iddb')
  try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/getReservationMail/mail/${idreservation}`, {
          params: {
                   id,
                   iddb 
                  } // Passer l'id en tant que paramètre
      });
      
      if (response.data.status === 200) {
          toast.success(response.data.message);
      } else {
          toast.error("Une erreur est survenue lors de l'envoi du mail.");
      }
  } catch (error) {
      console.error("Erreur lors de l'envoi du mail :", error);
      toast.error("Erreur lors de l'envoi du mail. Veuillez réessayer.");
  }
};


  const handleClickOutside = () => {
    if (openIndexe !== null) {
      setOpenIndexe(null);
    }
    if(openIndex !== null)
    {
      setOpenIndex(null)
    }
  };

  const handleClickDetail = async (nofacture)=>{
     await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/detail-facture/${nofacture}`).then((response)=>{
          setDetails(response.data.factures)
          setDet(true)
    }).catch((error)=>{
      console.log("Erreur lors de l'appel a l'API ",error)
     })
  }

  useEffect(() => {
    let taxe1 = 0, taxe2 = 0, taxe3 = 0, priceu = 0 , qte=0, mts = 0,mhts = 0;
      details.forEach(detail=>{
        taxe1 += detail['Taxe1']
        taxe2 += detail['Taxe2']
        taxe3 +=detail['Taxe3']
        priceu +=detail['Sales Prices']
        qte +=detail['Quantities Sales']
        mhts +=detail['Quantities Sales'] * detail['Sales Prices']
        mts +=detail['Quantities Sales'] * detail['Sales Prices']
      })
      setTotalTaxe1(taxe1)
      setTotalTaxe2(taxe2)
      setTotalTaxe3(taxe3)
      setPrixu(priceu)
      setQt(qte)
      setMht(mhts)
      setMt(mts)
}, [details]); 

const handleCloseDetail  = ()=>{
    setDet(false)
  }

  const handleShowDelivered = async (nofacture) => {
    try {
        const res = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/updateFacture/${nofacture}`);
        if (res.data.message) {
            toast.success(res.data.message);
            setOpenMode(false);
            
            const facturationsArray = Object.values(res.data.newfactur);
            setFacturations(facturationsArray);
        }
    } catch (error) {
        console.log("Erreur lors de l'appel à l'API:", error);
    }
};

  const handleOpenMode = (nofacture) => {
    setOpenMode(true); 
    setTimeout(()=>{
      setOpens(currentItem.filter(current => current.invoice_number === nofacture));
    }) 
  };

  const handleOpenModeFilter = (nofacture)=>{
    setOpenMode(true)
    setTimeout(()=>{
      setOpens(filteredFacturations.filter(fact=>fact.invoice_number === nofacture))
    })
  }

  const handleCloseMode = ()=>{
    setOpenMode(false)
  }

  const handleCloseModalRecu = ()=>{
    setIsModalRecu(false)
  }
    
  const ModalRecu = async(fac)=> await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/imprime-recu/${fac}`).then((res)=>{
        if(res.data.status === 200){
            setIsModalRecu(true)
           setRecu(res.data.recu)
           setInfo(res.data.info)
           setIdentifiantCustmer(res.data.identifiantCustmer)
           setIdentifiantSeller(res.data.identifiantSeller)
           //setCustomer(res.data.Customer)
           //setSeller(res.data.Seller)
        }
      }).catch((err)=>{
        console.log("Erreur lors de l'appel a l'API ",err)
      })

      useEffect(() => {
        let taxe1 = 0, taxe2 = 0, taxe3 = 0, priceu = 0 , qte=0, mts = 0,mhts = 0;
          recu.forEach(detail=>{
            taxe1 += detail['Taxe1']
            taxe2 += detail['Taxe2']
            taxe3 +=detail['Taxe3']
            priceu +=detail['invoice_sales_price']
            qte +=detail['quantity_sales']
            mhts +=detail['invoice_total_sales'] 
            mts += detail['total_before_taxes'] +  (detail['Taxe1'] + detail['Taxe2'] + detail['Taxe3'])
          })
          setTotalTaxe1(taxe1)
          setTotalTaxe2(taxe2)
          setTotalTaxe3(taxe3)
          setPrixu(priceu)
          setQt(qte)
          setMht(mhts)
          setMt(mts)
    }, [recu]);

     // Fonction pour gérer l'impression et fermer le modal après l'impression
    const handlePrint = () => {
      window.print();  
    };

    const { t, i18n } = useTranslation();
    const langue = i18n.language === 'fr'? 'fr_FR' : 'en_US'
  
  return (
    
    <div onClick={()=>handleClickOutside()} className="principal-div">
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
         <ToastContainer />
          <div className='flex flex-row bg-[#F7F7F7] h-screen'>
            {/* Section gauche */}
            <div className='w-2/3 barre_scroll'>
              {/* Navbar */}
              <div className='flex s:justify-between s:flex-row xs:flex-col s:h-20 xs:h-32 s:items-center s:space-x-8 xs:space-y-8 s:space-y-0 text-xl bg-white shadow-md s:px-6 xs:px-2 z-10 fixed w-2/3'>
                <div className='text-3xl'>
                   <img className="w-40 h-10  object-cover"  src={`data:image/png;base64,${logos}`} alt="Logo"/>
                </div>
                <strong><h1 className="text-2xl font-bold zen-tokyo-zoo-regular">ON-SITE</h1></strong> 
                <div>
                  <input type='search' onChange={handleReservationChange} className='bg-[#EEEEEE] h-10 px-3  outline-none text-base' placeholder={t("Entrer un mot clé")} />
                </div>
              </div>
              <div className='p-6 s:text-2xl xs:text-lg text-[#4E4E4E] mt-20'>{t("Listes des factures et réservations")}</div>

              <div className='flex s:flex-row xs:mt-7 xs:flex-col s:justify-center s:items-center s:space-x-6 xs:space-y-6 s:space-y-0 pl-6'>
                <input type='date' className='h-10 w-[220px] px-3 bg-white rounded-md' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                <input type='time' className='h-10 w-[100px] px-3 bg-white rounded-md' value={startTime} onChange={(e) => setStartTime(e.target.value)} />
              </div>

              <div className='p-6 mt-7'>
                <div className='flex s:flex-row s:space-x-6 xs:flex-col xs:space-y-6 s:space-y-0'>
                  {/* place reservé */}
                  <div onClick={OnFactureClick} 
                  className={`${bgColorf} pl-4 h-20 xs:w-[220px] s:w-[280px] cursor-pointer rounded shadow flex flex-row items-center space-x-3`}>
                    <div className='bg-white s:w-16 s:h-16 xs:w-14 xs:h-14 flex justify-center items-center rounded-full'>
                       {/* <img src={table} alt='table' />  */}
                       <FaFileInvoiceDollar size={24} />
                    </div>
                    <div  className='text-lg'>{t("Commandes")}</div>
                  </div>
                  {/* place disponible */}
                  <div onClick={OnReservationClick} 
                    className={`${bgColorr} pl-4 h-20 xs:w-[220px] s:w-[280px] cursor-pointer rounded shadow flex flex-row items-center space-x-3`}>
                    <div className='bg-white s:w-16 s:h-16 xs:w-14 xs:h-14 flex justify-center items-center rounded-full'>
                      {/* <img src={table} alt='table' /> */}
                      <FaCalendarAlt size={24} />
                    </div>
                    <div  className='s:text-lg xs:text-base text-dark'>{t("Confirmations de RSV")}</div>
                  </div>
                </div>
                <div className='grid s:grid-cols-1 xs:grid-cols-1 my-20 gap-y-6 '>
                  {
                    // Si aucune réservation filtrée n'est trouvée et qu'une recherche est en cours
                    itemsOfFilteredFacturation.length === 0 && (search || startDate) && facture && (
                      <>
                        <div className="text-center text-red-500 font-bold">{t("Aucune facture trouvée")}</div>
                      </>
                    )
                  }

                  {
                    itemsOfFilteredFacturation.length > 0 && (search || startDate) &&  facture && (
                      <table className="min-w-full border-collapse border table hover:table border-gray-300">
                          <thead>
                              <tr className="bg-gray-100">
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Action")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("No. Vente")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Date de CMD")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Nbre de designation")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Client")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Table")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Status")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Monant total payer")}</th>
                              </tr>
                          </thead>
                          <tbody>
                            {
                               itemsOfFilteredFacturation.map((facturation,indexe)=>(
                                <tr key={indexe} className={`${facturation.delivered === 'Yes' ? bgCommandeDelivre : facturation.delivered === 'No' ? bgCommandeNoDelivre : ''}`}>
                                    <th scope="row" className="px-4 py-2 border border-gray-300">
                                        <div className="relative inline-block text-left">
                                            <div>
                                                <button
                                                type="button"
                                                className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                id="menu-button"
                                                aria-expanded={openIndex === indexe}
                                                aria-haspopup="true"
                                                onClick={()=>toggleMenue(indexe)} // Trigger menu toggle on click
                                                >
                                                <BsThreeDotsVertical className="h-5 w-5 text-gray-400" /> {/* 3-dot icon */}
                                                </button>
                                            </div>
                                            {openIndexe === indexe && (
                                                <div
                                                    className="absolute left-1 z-10 mt-2 w-55 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                    role="menu"
                                                    aria-orientation="vertical"
                                                    aria-labelledby="menu-button"
                                                    tabIndex="-1"
                                                    >
                                                    <div className="py-1" role="none">
                                                        <Link
                                                            href="#"
                                                            className="block px-4 py-2 text-sm text-gray-700 flex items-center"
                                                            role="menuitem"
                                                            tabIndex="-1"
                                                            id="menu-item-0"
                                                            onClick={()=>handleClickDetail(facturation.invoice_number)}
                                                            >
                                                             <FaPlus size={20} color="dark" className="mr-2" /> 
                                                             {t("Détail")}
                                                        </Link>
                                                        <Link
                                                            href="#"
                                                            className="block px-4 py-2 text-sm text-gray-700 flex items-center"
                                                            role="menuitem"
                                                            tabIndex="-1"
                                                            id="menu-item-0"
                                                            onClick={()=>ModalRecu(facturation.invoice_number)}
                                                            >
                                                             <FaSyncAlt size={20} color="dark" className="mr-2"/> 
                                                             {t("Recu")}
                                                        </Link>
                                                        <Link
                                                            href="#"
                                                            className="block px-4 py-2 text-sm text-gray-700"
                                                            role="menuitem"
                                                            tabIndex="-1"
                                                            id="menu-item-0"
                                                            onClick={()=>handleOpenModeFilter(facturation.invoice_number)}
                                                            >
                                                           {/* Si la commande est livrée */}
                                                           {facturation.delivered === 'Yes' && (
                                                                <span className="flex items-center">
                                                                  <MdCheckCircle size={40} className="mr-2 text-green-500" /> {/* Icône vert */}
                                                                  {t("Etat de la commande : Livré")}
                                                                </span>
                                                              )}
                                                                                                                          
                                                              {/* Si la commande n'est pas livrée */}
                                                              {facturation.delivered === 'No' && (
                                                                <span className="flex items-center">
                                                                  <MdCheckCircle size={40} className="mr-2 text-gray-500" /> {/* Icône gris */}
                                                                  {t("Etat de la commande : Non livré")}
                                                                </span>
                                                              )}
                                                        </Link>
                                                        <Link
                                                            href="#"
                                                            className="block px-4 py-2 text-sm text-gray-700 flex items-center"
                                                            role="menuitem"
                                                            tabIndex="-1"
                                                            id="menu-item-1"
                                                            onClick={()=>handleDownloadFacture(facturation.invoice_number)}
                                                            >
                                                            <FaDownload size={20} color="dark" className="mr-2" />  {/* Ajout de "mr-2" pour un espacement à droite */}
                                                            {t("Télécharger la Facture")}
                                                        </Link>
                                                        <Link
                                                            href="#"
                                                            className="block px-4 py-2 text-sm text-gray-700 flex items-center"
                                                            role="menuitem"
                                                            tabIndex="-1"
                                                            id="menu-item-2"
                                                            onClick={()=>handleMailFacturSend(facturation.invoice_number)}
                                                            >
                                                             <FaPaperPlane size={20} color="dark" className="mr-2" />
                                                            {t("Envoyer la Facture au client")}
                                                        </Link>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </th>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.invoice_number}</td>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.Dates}</td>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.invoice_quantity}</td>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.Customers} </td>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.seat_id} </td>
                                    <td className="px-4 py-2 border border-gray-300">
                                        {facturation.delivered === 'Yes' && (<span>{t("Commande terminée")}</span>)}
                                        {facturation.delivered === 'No' && (<span>{t("Commande passée")}</span>)}

                                    </td>
                                    <td className="px-4 py-2 border border-gray-300">{Math.round(facturation.invoice_total_sales)} {device && device.sign}</td>
                              </tr>
                              ))
                            }
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="8">
                                <div className="pagination float-end">
                                  <button 
                                    disabled={currentPage === 1} 
                                    className="mr-4 text-2xl"
                                    onClick={handlePrevious}>
                                    <FaArrowLeft className="mt-3" /> 
                                  </button>
                                  
                                  <span> {currentPage} sur {totalPage}</span>
                                  
                                  <button 
                                    disabled={currentPage === totalPage}
                                    className="ml-4 text-2xl" 
                                    onClick={handleNextFact}>
                                      <FaArrowRight className="mt-3" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                     </table>              
                    )
                    
                  }
                 
                  {
                    (search==='' && startDate==='' && startTime==='') && facture &&  currentItem.length !== 0 &&(
                      <table className="min-w-full border-collapse border table hover:table border-gray-300">
                         <thead>
                              <tr className="bg-gray-100">
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Action")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("No. Vente")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Date de CMD")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Nbre de designation")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Client")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Table")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Status")}</th>
                              <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Monant total payer")}</th>
                              </tr>
                          </thead>
                          <tbody>
                            {
                              currentItem.map((facturation,indexe)=>(
                                     <tr key={indexe} className={`${facturation.delivered === 'Yes' ? bgCommandeDelivre : facturation.delivered === 'No' ? bgCommandeNoDelivre : ''}`}>
                                       <th scope="row" className="px-4 py-2 border border-gray-300">
                                        <div className="relative inline-block text-left">
                                            <div>
                                                <button
                                                  type="button"
                                                  className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                  id="menu-button"
                                                  aria-expanded={openIndex === indexe}
                                                  aria-haspopup="true"
                                                  onClick={()=>toggleMenue(indexe)} // Trigger menu toggle on click
                                                  >
                                                   <BsThreeDotsVertical className="h-5 w-5 text-gray-400" /> {/* 3-dot icon */}
                                                </button>
                                            </div>
                                            {openIndexe === indexe && (
                                                <div
                                                    className="absolute left-1 z-10 mt-2 w-55 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                    role="menu"
                                                    aria-orientation="vertical"
                                                    aria-labelledby="menu-button"
                                                    tabIndex="-1"
                                                    >
                                                    <div className="py-1" role="none">
                                                        <Link
                                                            href="#"
                                                            className="block px-4 py-2 text-sm text-gray-700 flex items-center"
                                                            role="menuitem"
                                                            tabIndex="-1"
                                                            id="menu-item-0"
                                                            onClick={()=>handleClickDetail(facturation.invoice_number)}
                                                            >
                                                              <FaPlus size={20} color="dark" className="mr-2" /> 
                                                              {t("Détail")}
                                                        </Link>
                                                        <Link
                                                            href="#"
                                                            className="block px-4 py-2 text-sm text-gray-700 flex items-center"
                                                            role="menuitem"
                                                            tabIndex="-1"
                                                            id="menu-item-0"
                                                            onClick={()=>ModalRecu(facturation.invoice_number)}
                                                            >
                                                              <FaSyncAlt size={20} color="dark" className="mr-2"/>
                                                              {t("Recu")}
                                                        </Link>
                                                        <Link
                                                              href="#"
                                                              className="block px-4 py-2 text-sm text-gray-700 flex items-center"
                                                              role="menuitem"
                                                              tabIndex="-1"
                                                              id="menu-item-0"
                                                              onClick={() => handleOpenMode(facturation.invoice_number)}
                                                            >
                                                              {/* Si la commande est livrée */}
                                                              {facturation.delivered === 'Yes' && (
                                                                <span className="flex items-center">
                                                                  <MdCheckCircle size={40} className="mr-2 text-green-500" /> {/* Icône vert */}
                                                                  {t("Etat de la commande : Livré")}
                                                                </span>
                                                              )}
                                                                                                                          
                                                              {/* Si la commande n'est pas livrée */}
                                                              {facturation.delivered === 'No' && (
                                                                <span className="flex items-center">
                                                                  <MdCheckCircle size={40} className="mr-2 text-gray-500" /> {/* Icône gris */}
                                                                  {t("Etat de la commande : Non livré")}
                                                                </span>
                                                              )}
                                                        </Link>
                                                        <Link
                                                              href="#"
                                                              className="block px-4 py-2 text-sm text-gray-700 flex items-center"  // Utilisation de flex pour aligner horizontalement
                                                              role="menuitem"
                                                              tabIndex="-1"
                                                              id="menu-item-1"
                                                              onClick={() => handleDownloadFacture(facturation.invoice_number)}
                                                            >
                                                              <FaDownload size={20} color="dark" className="mr-2" />  {/* Ajout de "mr-2" pour un espacement à droite */}
                                                              {t("Télécharger la Facture")}
                                                        </Link>
                                                        <Link
                                                            href="#"
                                                            className="block px-4 py-2 text-sm text-gray-700 flex items-center"
                                                            role="menuitem"
                                                            tabIndex="-1"
                                                            id="menu-item-2"
                                                            onClick={()=>handleMailFacturSend(facturation.invoice_number)}
                                                            >
                                                            <FaPaperPlane size={20} color="dark" className="mr-2" />
                                                            {t("Envoyer la Facture au client")}
                                                        </Link>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </th>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.invoice_number}</td>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.Dates}</td>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.invoice_quantity}</td>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.Customers} </td>
                                    <td className="px-4 py-2 border border-gray-300">{facturation.seat_id} </td>
                                    <td className="px-4 py-2 border border-gray-300">
                                        {facturation.delivered === 'Yes' && (<span>{t("Commande terminée")}</span>)}
                                        {facturation.delivered === 'No' && (<span>{t("Commande passée")}</span>)}

                                    </td>
                                    <td className="px-4 py-2 border border-gray-300">{Math.round(facturation.invoice_total_sales)} {device && device.sign}</td>
                                   </tr>                                   
                              ))
                            }
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="8">
                                <div className="pagination float-end">
                                  <button 
                                    disabled={currentPage === 1} 
                                    className="mr-4 text-2xl"
                                    onClick={handlePrevious}>
                                   <FaArrowLeft className="mt-3" />
                                  </button>
                                  
                                  <span> {currentPage} sur {Math.ceil(facturations.length/itemsPerPage)}</span>
                                  
                                  <button 
                                    disabled={currentPage === Math.ceil(facturations.length/itemsPerPage)}
                                    className="ml-4 text-2xl" 
                                    onClick={handleNextFact}>
                                      <FaArrowRight className="mt-3" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                     </table>
                     
                    )
                  }

                  {
                      // Si aucune réservation filtrée n'est trouvée et qu'une recherche est en cours
                      itemsOfFilteredReservation.length === 0 && (search || startDate || startTime) && reservation && (
                        <>
                          <div className="text-center text-red-500 font-bold">{t("Aucune réservation trouvée")}</div>
                        </>
                      )
                    }

                      {
                        itemsOfFilteredReservation.length > 0 && (search || startDate || startTime) && reservation && (
                          <table className="min-w-full border-collapse border table hover:table border-gray-300">
                            <thead>
                              <tr className="bg-gray-100">
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Action")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("NO. Reservation")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Nombre de designation")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Table")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Nom du client")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Date de la reservation")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Date et heure d'arrivée")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Date et heure de départ")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {itemsOfFilteredReservation.map((reserve, index) => (
                                <tr key={index}>
                                  <th scope="row" className="px-4 py-2 border border-gray-300">
                                    <div className="relative inline-block text-left">
                                      <div>
                                        <button
                                          type="button"
                                          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                          id="menu-button"
                                          aria-expanded={openIndex === index}
                                          aria-haspopup="true"
                                          onClick={() => toggleMenu(index)} // Toggle menu on click
                                        >
                                          <BsThreeDotsVertical className="h-5 w-5 text-gray-400" /> {/* 3-dot icon */}
                                        </button>
                                      </div>
                                      {openIndex === index && (
                                        <div
                                          className="absolute left-1 z-10 mt-2 w-55 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                          role="menu"
                                          aria-orientation="vertical"
                                          aria-labelledby="menu-button"
                                          tabIndex="-1"
                                        >
                                          <div className="py-1" role="none">
                                            {/* <Link
                                              href="#"
                                              className="block px-4 py-2 text-sm text-gray-700"
                                              role="menuitem"
                                              tabIndex="-1"
                                              id="menu-item-0"
                                            >
                                              Détail
                                            </Link> */}
                                            <Link
                                              href="#"
                                              className="block px-4 py-2 text-sm text-gray-700 flex items-center"
                                              role="menuitem"
                                              tabIndex="-1"
                                              id="menu-item-1"
                                              onClick={() => handeDowloadReservation(reserve.reservation_id)}
                                            >
                                              <FaDownload size={20} color="dark" className="mr-2" />  {/* Ajout de "mr-2" pour un espacement à droite */}
                                              Télécharger
                                            </Link>
                                            <Link
                                              href="#"
                                              className="block px-4 py-2 text-sm text-gray-700 flex items-center"
                                              role="menuitem"
                                              tabIndex="-1"
                                              id="menu-item-2"
                                              onClick={() => handleMailReservationSend(reserve.reservation_id)}
                                            >
                                              <FaPaperPlane size={30} color="dark" className="mr-2" />
                                              Envoyer Confirmation
                                            </Link>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </th>
                                  <td className="px-4 py-2 border border-gray-300">{reserve.group_reservation}</td>
                                  <td className="px-4 py-2 border border-gray-300">{reserve.quantities}</td>
                                  <td className="px-4 py-2 border border-gray-300">{reserve.seat_id}</td>
                                  <td className="px-4 py-2 border border-gray-300">{reserve.customer_last_name} {reserve.customer_name}</td>
                                  <td className="px-4 py-2 border border-gray-300">{reserve.starting_date} : {reserve.starting_time ? reserve.starting_time : ''}</td>
                                  <td className="px-4 py-2 border border-gray-300">{reserve.starting_date} : {reserve.starting_time ? reserve.starting_time : ''}</td>
                                  <td className="px-4 py-2 border border-gray-300">
                                    {reserve.ending_date ? reserve.ending_date : 'En cours...'}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              {filteredReservations.length > 0 &&  (
                                <tr>
                                  <td colSpan="7">
                                    <div className="pagination float-end">
                                      <button
                                        disabled={currentPage === 1}
                                        className="mr-4 text-2xl"
                                        onClick={handlePrevious}
                                      >
                                        <FaArrowLeft className="mt-3" />
                                      </button>

                                      <span>{currentPage} sur {totalPageReservation}</span>

                                      <button
                                        disabled={currentPage === totalPageReservation}
                                        className="ml-4 text-2xl"
                                        onClick={handleNext}
                                      >
                                        <FaArrowRight className="mt-3" />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tfoot>
                          </table>
                        )
                      }

                    {
                      (search==='' && startDate==='' && startTime==='') && reservation && currentItems.length !== 0 &&(
                        <table className="min-w-full border-collapse border table hover:table border-gray-300">
                           <thead>
                              <tr className="bg-gray-100">
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Action")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("NO. Reservation")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Nombre de designation")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Table")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Nom du client")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Date de la reservation")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Date et heure d'arrivée")}</th>
                                <th scope="col" className="px-4 py-2 border border-gray-300 uppercase">{t("Date et heure de départ")}</th>
                              </tr>
                            </thead>
                          <tbody>
                            {currentItems.map((reserve, index) => (
                              <tr key={index}>
                                <th scope="row" className="px-4 py-2 border border-gray-300">
                                  <div className="relative inline-block text-left">
                                    <div>
                                      <button
                                        type="button"
                                        className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        id="menu-button"
                                        aria-expanded={openIndex === index}
                                        aria-haspopup="true"
                                        onClick={() => toggleMenu(index)} // Toggle menu on click
                                      >
                                        <BsThreeDotsVertical className="h-5 w-5 text-gray-400" /> {/* 3-dot icon */}
                                      </button>
                                    </div>
                                    {openIndex === index && (
                                      <div
                                        className="absolute left-1 z-10 mt-2 w-55 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                        role="menu"
                                        aria-orientation="vertical"
                                        aria-labelledby="menu-button"
                                        tabIndex="-1"
                                      >
                                        <div className="py-1" role="none">
                                          {/* <Link
                                            href="#"
                                            className="block px-4 py-2 text-sm text-gray-700"
                                            role="menuitem"
                                            tabIndex="-1"
                                            id="menu-item-0"
                                          >
                                            Détail
                                          </Link> */}
                                          <Link
                                            href="#"
                                            className="block px-4 py-2 text-sm text-gray-700 flex items-center"
                                            role="menuitem"
                                            tabIndex="-1"
                                            id="menu-item-1"
                                            onClick={() => handeDowloadReservation(reserve.reservation_id)}
                                          >
                                             <FaDownload size={20} color="dark" className="mr-2" />  {/* Ajout de "mr-2" pour un espacement à droite */}
                                             {t("Télécharger")}
                                          </Link>
                                          <Link
                                            href="#"
                                            className="block px-4 py-2 text-sm text-gray-700 flex items-center"
                                            role="menuitem"
                                            tabIndex="-1"
                                            id="menu-item-2"
                                            onClick={() => handleMailReservationSend(reserve.reservation_id)}
                                          >
                                            <FaPaperPlane size={20} color="dark" className="mr-2" />
                                            {t("Envoyer Confirmation")}
                                          </Link>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </th>
                                <td className="px-4 py-2 border border-gray-300">{reserve.group_reservation}</td>
                                <td className="px-4 py-2 border border-gray-300">{reserve.quantities}</td>
                                <td className="px-4 py-2 border border-gray-300">{reserve.seat_id}</td>
                                <td className="px-4 py-2 border border-gray-300">
                                  {reserve.customer_last_name} {reserve.customer_name}
                                </td>
                                <td className="px-4 py-2 border border-gray-300">{reserve.starting_date}</td>
                                <td className="px-4 py-2 border border-gray-300">{reserve.starting_date}</td>
                                <td className="px-4 py-2 border border-gray-300">
                                  {reserve.ending_date ? reserve.ending_date : 'En cours...'}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="7">
                                <div className="pagination float-end">
                                  <button
                                   disabled={currentPage === 1}
                                    className="mr-4 text-2xl"
                                    onClick={handlePrevious}
                                  >
                                   <FaArrowLeft className="mt-3" />
                                  </button>

                                  <span>
                                    {currentPage} sur {Math.ceil(reservations.length/itemsPerPage)}
                                  </span>

                                  <button
                                    disabled={indexOfLastItem >= reservations.length}
                                    className="ml-4 text-2xl"
                                    onClick={handleNext}
                                  >
                                   <FaArrowRight className="mt-3"/>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      )
                    }

                    {det && details && (
                       <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                          <div className="bg-white p-8 rounded shadow-lg w-full sm:w-[90%] md:w-[100%] lg:w-[60%] xl:w-[50%]">
                            <div className="flex justify-end space-x-4">
                              <div className="flex-grow text-2xl">
                               {t("Detail de la facture")} n° <strong>{details[0]["No. Invoice"]}</strong>
                              </div>
                              <button
                                className="bg-[#ff0000] text-white px-4 py-2 rounded mb-2"
                                onClick={handleCloseDetail}
                              >
                                <span className="w-50 h-50"><MdOutlineCancel /></span>
                              </button>
                            </div>
                            <hr className="mb-5" />
                            <div className="text-xl">
                              <p><strong className="mr-2">{t("Nom du client")}:</strong>{details[0].Customers}</p>
                              <p><strong className="mr-2">{t("Date de la commande")}:</strong>{details[0].Dates}</p>
                              <p><strong className="mr-2">{t("Description")}:</strong>{details[0].Descriptions}</p>
                            </div>
                            <hr className="mb-5 mt-5" />
                        
                            {/* Ajout de l'overflow-x-auto pour le défilement horizontal */}
                            <div className="overflow-x-auto">
                              <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-lg">
                                <thead>
                                  <tr className="bg-black text-white uppercase text-sm leading-normal">
                                    <th className="py-3 px-6 border border-gray-300 text-left">{t("produit")}</th>
                                    <th className="py-3 px-6 border border-gray-300 text-left">{t("prix unitaire")}</th>
                                    <th className="py-3 px-6 border border-gray-300 text-left">{t("qté")}</th>
                                    <th className="py-3 px-6 border border-gray-300 text-left">{t("Montant ht")}</th>
                                    <th className="py-3 px-6 border border-gray-300 text-left">{t("Taxe")} 1</th>
                                    <th className="py-3 px-6 border border-gray-300 text-left">{t("Taxe")} 2</th>
                                    <th className="py-3 px-6 border border-gray-300 text-left">{t("Taxe")} 3</th>
                                    <th className="py-3 px-6 border border-gray-300 text-left">{t("Montant total")}</th>
                                  </tr>
                                </thead>
                                <tbody className="text-gray-600 text-sm font-light">
                                  {details && details.map((detail, index) => (
                                    <tr key={index} className="border-b border-gray-300 hover:bg-gray-100">
                                      <td className="py-3 px-6 border border-gray-300">{detail['Items Names']}</td>
                                      <td className="py-3 px-6 border border-gray-300">{detail['Sales Prices']}</td>
                                      <td className="py-3 px-6 border border-gray-300">{detail['Quantities Sales']}</td>
                                      <td className="py-3 px-6 border border-gray-300">{detail['Quantities Sales'] * detail['Sales Prices']}</td>
                                      <td className="py-3 px-6 border border-gray-300">{detail['Taxe1']}</td>
                                      <td className="py-3 px-6 border border-gray-300">{detail['Taxe2']}</td>
                                      <td className="py-3 px-6 border border-gray-300">{detail['Taxe3']}</td>
                                      <td className="py-3 px-6 border border-gray-300">{detail['Quantities Sales'] * detail['Sales Prices'] + detail['Taxe1'] + detail['Taxe2'] + detail['Taxe3']}</td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td className="border-b border-gray-300 hover:bg-gray-100">Total:</td>
                                    <td className="py-3 px-6 border border-gray-300">{prixu}</td>
                                    <td className="py-3 px-6 border border-gray-300">{qt}</td>
                                    <td className="py-3 px-6 border border-gray-300">{mht}</td>
                                    <td className="py-3 px-6 border border-gray-300">{totalTaxe1}</td>
                                    <td className="py-3 px-6 border border-gray-300">{totalTaxe2}</td>
                                    <td className="py-3 px-6 border border-gray-300">{totalTaxe3}</td>
                                    <td className="py-3 px-6 border border-gray-300">{mt} {device && device.sign}</td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                      </div>
                     
                      
                     )}

                    {openMode && opens.length > 0  &&  (
                          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white p-8 rounded shadow-lg">
                               
                                <div className="overflow-x-auto">
                                    <p>
                                      {opens && opens.length > 0
                                        ? `${t("Vous êtes sur le point de vouloir effectuer la commande de")} ${opens[0].Customers}`
                                        : t('Aucune commande à afficher.')}
                                    </p>
                                </div>
                                <div className="flex justify-end  space-x-4">
                                  <button
                                    className="bg-[rgb(32,147,19)] text-white px-4 py-2 rounded mb-2"
                                    onClick={() => handleShowDelivered(opens[0].invoice_number)}
                                  >
                                  <span className="w-50 h-50">{t("Oui")}</span> 
                                  </button>
                                  <button
                                    className="bg-[#ff0000] text-white px-4 py-2 rounded mb-2"
                                    onClick={handleCloseMode}
                                  >
                                  <span className="w-50 h-50">{t("Non")}</span> 
                                  </button>
                                </div>

                            </div>
                          </div>
                    )}

                    {isModalRecu && (
                      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                                   <div className="bg-white p-6 rounded shadow-lg w-[50%] sm:w-[400px] md:w-[400px] max-h-screen overflow-y-auto">
                                   <>
                                     <div className="items-center justify-center">
                                       <div className="text-center">
                                         <div className="flex items-center justify-center">
                                                 <p>
                                                   <img className="w-10 h-10 rounded-full object-cover"  src={`data:image/png;base64,${info.logo}`} alt="Logo"/>
                                                 </p>
                                                 <h2 className="ml-4 text-center text-xl bg-dark-600">
                                                   <strong>{info.name}</strong>
                                                 </h2>
                                           </div>
                                           <p className="mb-4">{info.Type},{info.address}  {info.phone} / {info.email} </p>
                                       </div>
                                     </div>
                                       <div className="">
                                       <div className="">
                                           {/* Première colonne */}
                                           <div className="flex-grow">
                                           {recu[0] && recu[0].Customers ? (
                                               <>
                                               <p>
                                                   <span className="inline ">{t("Table")}:</span> 
                                                   <span className="flex-grow w-1/3 border-b border-dotted mx-2">-----------------------</span>
                                                   <span className="inline float-right">{recu[0].Seat_id}</span>
                                               </p>
                                               <p>
                                                   <span className="inline ">{t("Nom du Client")}:</span> 
                                                   <span className="flex-grow w-1/3 border-b border-dotted mx-2">----------------</span>
                                                   <span className="inline float-right">{recu[0].Customers}</span>
                                               </p>
                                               <p>
                                                   <span className="inline ">{t("Identification")}:</span>
                                                   <span className="flex-grow w-1/3 border-b border-dotted mx-2">--------------</span>
                                                   <span className="inline float-right">{identifiantCustmer}</span>
                                               </p>
                     
                                               </>
                                           ) : (
                                               <p>{t('Client introuvable')}</p>
                                           )}
                                           </div>
                     
                                           {/* Deuxième colonne */}
                                           <div className="flex-grow ">
                                           {recu[0] && recu[0].employees_names ? (
                                               <>
                                               <p>
                                                   <span  className="inline">{t("Vendeur")}:</span>
                                                   <span className="flex-grow w-1/3 border-b border-dotted mx-2">----------------</span>
                                                   <span className="inline float-right">{recu[0].employees_names} {recu[0].employees_firstname || ''}</span>
                                               </p>
                                               <p>
                                                   <span className="inline">{t("Identification")}:</span>
                                                   <span className="flex-grow w-1/3 border-b border-dotted mx-2">--------------</span>
                                                   <span className="inline float-right">{identifiantSeller}</span>
                                               </p>
                                         
                                       
                                               </>
                                           ) : (
                                               <p>{t("Employe introuvable")}</p>
                                           )}
                                           </div>
                     
                                           {/* Deuxième colonne */}
                                           <div className="flex-grow">
                                               {recu[0] && recu[0].Dates ? (
                                                   <>
                                                       <span className="inline ">{t("Date & Heure")}:</span>
                                                       <span className="flex-grow w-1/3 border-b border-dotted mx-2">---------</span>
                                                       <span className="inline float-right">
                                                           {recu[0].Dates || '24/11/2024'}
                                                               
                                                           <span className="ml-2">
                                                               { new Date().getHours().toString().padStart(2,'0')}
                                                                           :
                                                               { new Date().getMinutes().toString().padStart(2,'0')}
                                                                           :
                                                               { new Date().getSeconds().toString().padStart(2,'0')}
                     
                                                           </span>           
                                                         
                                                       </span>
                                                   </>
                                               ) : (
                                                   <p>{t("Employe introuvable")}</p>
                                               )}
                                           </div>
                                       </div>
                                       </div>
                     
                                       {/* Table pour les détails */}
                                       <div className="overflow-x-auto flex justify-start">
                                       <table className="table-auto w-full md:w-[350px] text-center border-collapse my-2">
                                           <thead>
                                               <tr>
                                               <th className="py-2 text-left">{t("Article")}</th>
                                               <th className="py-2 text-right">{t("Qte")}</th>
                                               <th className="py-2 text-right">{t("Prix")}</th>
                                               <th className="py-2 text-right">{t("Montant")}</th>
                                               </tr>
                                           </thead>
                                           <tbody>
                                               {recu.map((rec, index) => (
                                               <tr key={index}>
                                                   <td className="text-left">{langue === 'fr_FR' ? rec.items_fr_name : rec.items_names}</td>
                                                   <td className="text-center">{rec.quantity_sales}</td>
                                                   <td className="text-right">{rec.invoice_sales_price}</td>
                                                   <td className="text-right">{rec.total_before_taxes}</td>
                                               </tr>
                                               ))}
                                               <tr>
                                                 <td colSpan="3" className="text-right font-bold py-2">{t("Total")}:</td>
                                                 <td className="text-right font-bold py-2">{Math.round(mt)}{device && device.sign}</td>
                                               </tr>
                     
                                               {recu[0] && recu[0].Taxe1_Name && (
                                               <tr>
                                                   <td colSpan="3" className="text-right font-bold py-2">{t("Taxe: ") + recu[0].Taxe1_Name}</td>
                                                   <td className="text-right py-2">{recu[0].Taxe1}</td>
                                               </tr>
                                               )}
                                               {recu[0] && recu[0].Taxe2_Name && (
                                               <tr>
                                                   <td colSpan="3" className="text-right font-bold py-2">{t("Taxe: ") + recu[0].Taxe2_Name}</td>
                                                   <td className="text-right py-2">{recu[0].Taxe2}</td>
                                               </tr>
                                               )}
                                                {recu[0] && recu[0].Taxe3_Name && (
                                               <tr>
                                                   <td colSpan="3" className="text-right font-bold py-2">{t("Taxe: ") + recu[0].Taxe3_Name}</td>
                                                   <td className="text-right py-2">{recu[0].Taxe3}</td>
                                               </tr>
                                               )}
                                                <tr>
                                                   <td colSpan="3" className="text-right font-bold py-2">{t("Total des Taxes")}:</td>
                                                   <td className="text-right py-2">{totalTaxe1 + totalTaxe2 + totalTaxe3} {device && device.sign}</td>
                                               </tr>
                                           </tbody>
                                           
                                       </table>
                                     
                                       </div>
                                       <p className="text-center">{t("Transaction en success")}</p>
                                       {/* Bouton d'impression */}
                                       <div className="flex justify-center mt-4">
                                           <button
                                               onClick={handlePrint}
                                               className="bg-[#4CAF50] text-white px-6 py-2 rounded hover:bg-[#45a049]">
                                               {t("Imprimer")}
                                           </button>
                                           <button
                                               onClick={() =>handleCloseModalRecu()}
                                               className="bg-[#ff0000] text-white ml-4 px-6 py-2 rounded hover:bg-[#cc0000]">
                                               {t("Ignorer")}
                                         </button>
                                       </div>
                     
                                   </>
                                   </div>
                               </div>
                    )}
                </div>
              </div>
            </div>
            <div className='w-1/3 bg-white barre_scroll'>
              <Header />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Document