import React, { useState, useEffect} from 'react';
import Loading from '../../COMPONENTS/Loading';
import Header from '../../COMPONENTS/Header';
import {  ToastContainer } from 'react-toastify';
import {  useNavigate } from 'react-router-dom';
import { accountService } from "../../services/Account.service";
import { profil } from "../../API/profil/profil";
import { logo } from "../../API/Logo/logo";
import FormProfil from "../../COMPONENTS/FormProfil";
import pp from "../../ASSETS/Image/african-teenage-girl-portrait-happy-smiling-face.jpg";
import { FaUser } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';

function Profil() {
  
  const [loading, setLoading] = useState(true);
  const [profils, setProfils] = useState([]);
  const [regle,setRegle]= useState('');
  const [data,setData] = useState(localStorage.getItem('iddb'));
  const [showInput,setShowInput] = useState(true)
  const id = accountService.getId();
  let navigate = useNavigate();
  const logout = () => {
    accountService.logout();
    navigate('/');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    profil(id).then((response) => {
      setProfils(response.profils);
      setRegle(response.reglement)
    }).catch(error => {
      console.log("Erreur lors de la récupération " + error);
    });
  }, [id]);

  const [logos,setLogos] = useState('');
  useEffect(()=>{
         logo().then((response)=>{
          setLogos(response.logo64)
         }).catch((error)=>{
          console.log("Erreur du chargement ",error)
         })
  },[])
 const databases = JSON.parse(localStorage.getItem('bases'))
 useEffect(() => {
  if (databases.length === 1) {
    setShowInput(false);
  }
}, [databases]);
 const onChange = (e)=>{
   setData(e.target.value)
   window.location.reload();
 }

 useEffect(()=>{
  if(databases.length > 1){
    const currentdatabase= databases.find((curr)=>curr.Customers_Numbers === parseInt(data))
    //console.log(currentdatabase.customer_db)
    localStorage.setItem('database',currentdatabase.customer_db)
    localStorage.setItem('iddb',currentdatabase.Customers_Numbers)
    profil(id).then((response) => {
      setProfils(response.profils);
      setRegle(response.reglement)
    }).catch(error => {
      console.log("Erreur lors de la récupération " + error);
    });
  
    logo().then((response)=>{
      setLogos(response.logo64)
     }).catch((error)=>{
      console.log("Erreur du chargement ",error)
     })
  }
 },[id,data,databases])

 const { t, i18n } = useTranslation();
 
  return (
   <div>

     {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (

        <div className='flex flex-row bg-[#F7F7F7] h-screen' >
          {/* Section gauche */}
          <div className='w-2/3 barre_scroll ' >
            <div  className="flex flex-row bg-[#F7F7F7] h-screen">
                <div>
                      <div className='flex s:justify-between s:flex-row xs:flex-col s:h-20 xs:h-32 s:items-center s:space-x-8 xs:space-y-8 s:space-y-0 text-xl bg-white shadow-md s:px-6 xs:px-2 z-10 fixed w-2/3'>
                          <div className='text-3xl'>
                            <img className="w-40 h-10  object-cover"  src={`data:image/png;base64,${logos}`} alt="Logo"/>
                          </div>
                        <strong><h1 className="text-2xl font-bold zen-tokyo-zoo-regular">ON-SITE</h1></strong> 
                    </div>
                    <div className="p-6 s:text-2xl xs:text-lg text-[#4E4E4E] mt-20">
                      {t("Mon compte")}
                    </div>

                  {
                    showInput && (
                      <div className='sm:mt-10 flex s:flex-row xs:flex-col s:justify-center s:items-center s:space-x-6 xs:space-y-6 s:space-y-0 pl-6'>
                          <div className="border-2 border-[#C2C2C2] sm:w-[300px]  md:w-[340px] rounded-md flex items-center px-3">
                            <FaUser className="text-[#C2C2C2]" /> 
                            <select
                              className="w-full px-2 h-10 outline-none"
                              name="name"
                              value={data}
                              onChange={onChange}
                            >
                              <option disabled >{t("Changer d'entreprise")}</option>
                              {
                                databases.map((base,index)=>(
                                  <option key={index} value={base.Customers_Numbers}>{base.Names}</option>
                                ))
                              }
                              
                            </select>
                         </div>
                     </div>
                    )
                  }
                    
                       
                    <div className="p-6 mb-10">
                        <div className="grid md:grid-cols-2 xs:grid-cols-1 gap-y-6">
                          <div className="flex s:flex-row xs:flex-col bg-white s:w-[400px] shadow items-center space-x-5 py-6 xs:px-3 s:px-4 s:py-4 rounded-lg h-full">
                            <img
                              src={profils?.Profils ? `data:image/png;base64,${profils.Profils}` : pp}
                              alt="pp"
                              className="w-20 h-20 rounded-full border-4 border-black"
                            />
                            <div className="flex flex-col items-center">
                              <div className="text-xl font-semibold">
                                {profils ? profils.LastName + ' ' + profils.FirstName : 'Kouakou Marie ange'}
                              </div>
                              <div className="font-light">{profils ? profils['Email'] : 'kouakou@gmail.com'}</div>
                            </div>
                          </div>

                          <div className="text-lg">
                            <div className="text-[#4E4E4E] px-4">{t("Fonction")} :</div>
                            <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                              {profils ? profils.fr_Positions : 'Caissieère'}
                            </div>
                          </div>

                          <div className="text-lg">
                            <div className="text-[#4E4E4E] px-4">{t("ID Employé")} :</div>
                            <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                              {profils ? profils.az_id : 'ED435618901'}
                            </div>
                          </div>

                          <div className="text-lg">
                            <div className="text-[#4E4E4E] px-4">{t("Adresse")} :</div>
                            <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                              {profils ? profils.Adress : "Abidjan, Côte d'ivoire"}
                            </div>
                          </div>

                          <div className="text-lg">
                            <div className="text-[#4E4E4E] px-4">{t("Numéro d'urgence")} :</div>
                            <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                              {profils ? profils.Phones : '+225 05 34 54 34 34'}
                            </div>
                          </div>

                          <div className="text-lg">
                            <div className="text-[#4E4E4E] px-4">{t("Nom d'utilisateur")} :</div>
                            <div className="bg-white h-9 rounded shadow s:w-[300px] px-4 py-1">
                              {profils ? profils['Email'] : 'kouakou@gmail.com'}
                            </div>
                          </div>
                        </div>

                      <div className="h-full mt-14 bg-white p-4 flex flex-col rounded shadow space-y-4">
                        <div className="text-xl font-semibold text-[#D19E06]">
                          {t("Règlement intérieur & procédure d'urgence")}
                        </div>
                        <div className="text-lg">
                          {/* Texte du règlement intérieur */}
                          {regle && (
                            regle.fr_reglement
                          )}
                        </div>
                      </div>

                      <button onClick={logout} className="bg-[#D7D7D7] shadow w-full mt-14 h-9 py text-lg rounded font-medium">
                        {t("Déconnexion")}
                      </button>

                      <div className="md:hidden sm:block">
                          <FormProfil/>
                      </div>
                    </div>
                </div>
            </div>
          </div>
           {/* Section droite */}
           <div className='w-1/3 bg-white barre_scroll '>
                  <div>
                     <Header></Header> 
                    <div className="md:block sm:hidden">
                        <FormProfil/>
                    </div>
                 </div>
                <ToastContainer></ToastContainer>
            </div>
        </div>
      )}
   </div>
  )
}

export default Profil