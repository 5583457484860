import axiosInstance from "../../_utils/axiosConfig";
export const forgot = async(mail)=>{
    try{
        const response = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/api/reinitialisation-password`,mail);
     return response.data;
    }catch(error){
        console.log("Erreur lors de l'appel a l'API ",error)
        throw error;
    }
    
}