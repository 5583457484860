import axios from 'axios';
import axiosInstance from '../../_utils/axiosConfig';

// Fonction de connexion
export const login = async (credentials) => {
  try {
    const response = await axiosInstance.post( `${process.env.REACT_APP_BASE_URL}/api/login`,credentials);
    return response.data;
  } catch (error) {
    console.error('Erreur du serveur :', error);
    // Relance l'erreur pour qu'elle soit gérée ailleurs si nécessaire
    throw error;
  }
};
