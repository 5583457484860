import restoImage from "../../ASSETS/Image/top-view-cooked-chicken-spiced-up-with-potatoes-dark-surface.jpg";
import { FaUserLarge } from "react-icons/fa6";
import { IoIosEye, IoIosEyeOff, IoIosLock } from "react-icons/io";
import {Link,useNavigate} from 'react-router-dom'
import { IoReturnUpBack } from "react-icons/io5";
import Loading from '../../COMPONENTS/Loading';
import { useState, useEffect } from 'react';
import {login } from '../../API/login/login'
import { accountService } from "../../services/Account.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";

function Login() {

  const [showCompany,setShowCompany] = useState(false)
  const [company,setCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [passwordVisible,setPasswordVisible]=  useState(false)

  const {t,i18n} = useTranslation();

      useEffect(() => {
        const timer = setTimeout(() => {
          // Après 5 secondes, masquer le spinner et rediriger l'utilisateur
          setLoading(false);
        }, 2000); // 5000 millisecondes = 5 secondes
    
        // Nettoyer le timer si le composant est démonté avant la fin du délai
        return () => clearTimeout(timer);
      }, []);
      const navigate = useNavigate();
      useEffect(() => {
        const token = accountService.getToken();
        if (token) {
          navigate('/');
        }
      }, [navigate]);
      const [credentials,setCredentials] = useState({
        login:'',
        password:''
      })
    let mdp = localStorage.getItem('mdp')
      if(mdp)
      {
        toast.success(t("Mot de passe reinitialiser avec succès"));
        localStorage.removeItem('mdp')
      }
      
      const onChange = (e) => {
        const { name, value } = e.target;

        // Si le champ est "password", on met à jour directement
        if (name === "password") {
          setCredentials({ ...credentials, [name]: value });
          return;
        }
      
        // Vérifier si la saisie est un email ou contient des lettres
        const isEmailOrText = /[a-zA-Z@]/.test(value);
      
        if (isEmailOrText) {
          // Si c'est un email ou texte, mettre à jour normalement
          setCredentials({ ...credentials, [name]: value });
          return;
        }
      
        // Supprimer les tirets avant traitement
        let cleanedValue = value.replace(/[^0-9]/g, '');
      
        // Ajouter un tiret automatiquement après chaque 2 chiffres
        if (cleanedValue.length > 2) {
          cleanedValue = cleanedValue.match(/.{1,2}/g).join('-');
        }
      
        // Limiter à 8 chiffres (sans compter les tirets)
        if (cleanedValue.replace(/-/g, '').length > 8) {
          return; // Bloquer la saisie après 8 chiffres
        }
      
        setCredentials({ ...credentials, [name]: cleanedValue });
      };
      
               
      const onSubmit = async (e) => {
        e.preventDefault();
        if (credentials.login === '') {
          toast.error(t("Veuillez remplir les champs"));
        } else if (credentials.password === '') {
          toast.error(t("Veuillez remplir les champs"));
        } else {
          try {

            const response = await login(credentials);
          //  console.log(response)
            
            // Vérifiez si la réponse contient le token et le customer avant d'accéder à ces valeurs
            if (response.status === 200) {
              setShowCompany(true)
              setCompany(response.companies)
              let bases = JSON.stringify(response.companies)
              localStorage.setItem('bases',bases)
              if(response.companies.length === 1){
                const db = response.companies[0]['customer_db']
                const idData = response.companies[0]['Customers_Numbers']
                    localStorage.setItem('database',db);
                    localStorage.setItem('idData',idData);
                    let country = JSON.stringify(response.customer.Country)
                    localStorage.setItem('country',country)
                    accountService.SaveToken(response.token);
                    accountService.SaveId(response.customer.Customers_Numbers);
                    navigate('/');
              }
              if(response.status === 402){
                toast.error(response.message)
              }
            } else {
              toast.error(response.message);
            }
          } catch (error) {
            console.error('Erreur lors de l\'envoi du formulaire :', error);
            
           // const err = error as any; // Caster l'erreur en `any`
            
            if (error.response) {
              const errorMessage = error.response.data.message || 'Une erreur est survenue';
              toast.error(errorMessage);
            } else {
              toast.error(error.message || 'Une erreur est survenue');
            }
          }
        }
      };

      const handleDatabase =async (dbname,iddb)=>{
         localStorage.setItem('database',dbname)
         localStorage.setItem('iddb',iddb)
         try{
            const response = await login(credentials);
            let country = JSON.stringify(response.customer.Country)
                localStorage.setItem('country',country)
                accountService.SaveToken(response.token);
                accountService.SaveId(response.customer.Customers_Numbers);
                navigate('/');
                toast.success(response.message);
         }catch(error){
             toast.error("Erreur",error)
         }
       
      }

      const handleVisibilityOff = ()=>{
        setPasswordVisible(true)
      }
      const handleVisibilityOn = ()=>{
        setPasswordVisible(false)
      }
  return (
    <div>
      {loading ? (
        // Afficher le spinner tant que loading est true
        <Loading />
      ) : (
        <>
       <div
            className="bg-center bg-cover bg-no-repeat h-screen flex  justify-center"
            style={{ backgroundImage: `url(${restoImage})` }}
          >
          {
            !showCompany && (
             
                <form onSubmit={onSubmit} className="bg-white   w-[600px] flex flex-col space-y-4 text-lg items-center justify-center ">
                 
                   {/*  <div className="text-4xl font-bold">
                           <img className="w-40 h-40 rounded-full object-cover" src={`data:image/png;base64,${logos}`} alt="Logo"/>
                       </div>
                  */}
                  <div className="text-2xl font-bold zen-tokyo-zoo-regular mt-4">AZ</div>
                  <div className="text-2xl font-bold zen-tokyo-zoo-regular mt-4">ON-SITE</div>
                  <div className="font-semibold">{t("Connectez-vous a votre compte")}</div>
                  <div className="bg-[#EEEEEE] s:w-[400px] xs:w-[240px] sm:w-[300px] flex flex-row items-center px-3">
                    <FaUserLarge className="text-[#a5a5a5]" />
                    <input
                      type="text"
                      value={credentials.login}
                      name="login"
                      onChange={onChange}
                      className=" h-12 px-3 bg-transparent outline-none text-base"
                      placeholder={t("Nom d'utilisateur")}
                    />
                    
                  </div>
                  <ToastContainer />
                  <div className="bg-[#EEEEEE] s:w-[400px] xs:w-[240px] sm:w-[300px] flex flex-row items-center px-3 justify-between">
                    <div className="flex items-center">
                        <IoIosLock className="text-[#a5a5a5] text-xl" />
                        <input
                          type={passwordVisible ? 'text' : 'password'}
                          value={credentials.password}
                          name="password"
                          onChange={onChange}
                          className=" h-12 px-3 bg-transparent outline-none text-base"
                          placeholder={t("Mot de passe")}
                        
                        />
                    </div>
                    <div className="">
                         {
                          !passwordVisible && (
                            <IoIosEyeOff className="text-[#a5a5a5] text-xl "  onClick={handleVisibilityOff}  />
                          )
                        }
                        {
                          passwordVisible && (
                            <IoIosEye className="text-[#a5a5a5] text-xl"  onClick={handleVisibilityOn} />
                          )
                        }
                      
                    </div>
                  </div>
                  <Link to="/forgotPassword">{t("Mot de passe oublié ?")}</Link>
                  <button className="bg-[#D19E06] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700 hover:bg-white hover:text-[#D19E06] s:w-[400px] xs:w-[240px] sm:w-[300px] text-white h-12">
                    {t("Connexion")}
                  </button>

                  <Link to='/' className="flex hover:text-[#D19E06] items-center space-x-2 text-sm">
                    <IoReturnUpBack className="text-xl" />
                    <div>{t("Retour a la page d'accueil")}</div>
                  </Link>
                </form>
            )
          }

          {
            showCompany && (
              
                <div className="bg-white w-[600px] flex flex-col space-y-7 text-lg items-center justify-center ">
                 
                  <div className="text-2xl font-bold zen-tokyo-zoo-regular mt-4">AZ</div>
                  <div className="text-2xl font-bold zen-tokyo-zoo-regular">ON-SITE</div>
                  <div className="font-semibold">{t("Choisissez une entreprise")}</div>
                  
                  <div className='grid s:grid-cols-2 xs:grid-cols-1 my-20 gap-y-6'>
                        {
                         company && company.map((comp, index) => (
                          <div key={index} className='bg-[#209313] flex flex-row items-center space-x-6 sm:w-[200px] md:w-[100px] rounded-md shadow p-3 mb-4 ml-3 '>
                           
                            <div className='w-[180px] '>
                              <div onClick={()=>handleDatabase(comp.customer_db,comp.Customers_Numbers)} className='cursor-pointer text-sm text-white  italic font-semibold'>{comp.Names}</div>
                            </div>
                           
                          </div>
                        ))
                        }
                  </div>
                
                </div>
            )
          }
      </div>
      </>
      )}
    </div>
   
  );
}
export default Login;
