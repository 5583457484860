import { BsCalendarDate } from "react-icons/bs";
import { FaFilePdf, FaGlobe, FaPowerOff, FaUser } from "react-icons/fa6";
import { IoFastFoodSharp } from "react-icons/io5";
import { MdEventBusy, MdOutlineAccessTime } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import { accountService } from "../services/Account.service";
import { CartContext } from "../CartContext";
import { CiGlobe } from "react-icons/ci";
import { useTranslation } from "react-i18next";

export default function Header()
{
    const location = useLocation();
    const isCommande = location.pathname==="/"? " text-white " :'';
    const isProfil = location.pathname==="/profil"? " text-white " :'';
    const isReservation = location.pathname==="/reservation"? " text-white " :'';
    const isdocument = location.pathname==="/document"? " text-white " :'';
  let navigate = useNavigate();
    const { setCart} = useContext(CartContext);    // compteur, nombre de commande
  
   const logout = ()=>{
         accountService.logout();
         localStorage.removeItem('database')
         localStorage.removeItem('panier')
         localStorage.removeItem('paiementDetails');
         setCart([])
         navigate('/login')
   }  

   const { t, i18n } = useTranslation();
   const langue = i18n.language;
   const changerLangue = (lang) => {
    if (i18n && i18n.changeLanguage) {
      i18n.changeLanguage(lang); // Now this should work
      setIsOpen(false)
    } else {
      console.error("i18n or i18n.changeLanguage is not available.");
    }
  };
    const [isOpen, setIsOpen] = useState(false);
      const toggleDropdown = ()=>{
       setIsOpen(!isOpen);
      }
   
   return  (<div className='md:w-3/3 bg-white barre_scroll mb-4'>
  <div className='bg-black flex xs:flex-col md:flex-row md:h-20 xs:h-96 md:space-x-4 xs:space-y-4 md:space-y-0 md:items-center justify-center text-[#D19E06] z-10 fixed top-0  w-1/3'>
    {/* Mon compte */}
    <Link to='/profil' className={'md:ml-4 flex flex-col items-center hover:text-white cursor-pointer'+ isProfil}>
        <FaUser className='text-2xl' />
        <div className="md:ml-3">{t('compte')}</div>
    </Link>
    {/* Commande */}
    <Link to='/' className={'flex flex-col items-center hover:text-white cursor-pointer'+isCommande}>
        <IoFastFoodSharp className='text-2xl' />
        <div>{t('Commande')}</div>
    </Link>
    {/* Reservation */}
    <Link to='/reservation' className={'flex flex-col items-center  cursor-pointer'+isReservation}>
       <MdEventBusy  className='text-2xl' />
        <div>{t('Reservation')}</div>
    </Link>
     {/* Document */}
     <Link to='/document' className={'flex flex-col items-center  cursor-pointer'+isdocument}>
        <FaFilePdf size={30}  />
        <div>{t('Documents')}</div>
    </Link>
    {/* Deconnexion */}

    <div className="relative inline-block text-left flex justify-center items-center">
  <button
    onClick={toggleDropdown}
    className="text-[#D19E06] px-4 py-2 rounded-md focus:outline-none"
  >
    <CiGlobe size={30} />
    <div>{langue.toUpperCase()}</div>
  </button>

  {isOpen && (
    <div className="bg-black absolute mt-40 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
      <div className="py-1">
        <div
          onClick={()=>changerLangue('fr')}
          className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
        >
          FR
        </div>
        <div
          onClick={()=>changerLangue('en')}
          className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
        >
          EN
        </div>
        <div onClick={logout} className="flex flex-col items-center  cursor-pointer">
          <div className="flex">
            <p className="mr-4">
              <FaPowerOff className='text-xl' /> 
             </p> 
             <p>
               {t('Déconnexion')}
             </p>
             
           </div>
        </div>
      </div>
    </div>
  )}
</div>

   </div>
 </div>)
}


 