import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import fr from './locales/fr.json';

const userLanguage = navigator.language || navigator.languages[0];

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      fr: { translation: fr },
    },
    lng: (userLanguage === 'fr-FR' ? 'fr' : 'en'),
    fallbackLng: 'fr',
    interpolation: { escapeValue: false },
  });

export default i18n;