import { paiement } from "../../../API/Paiement/paiement"
//import { useNavigate } from 'react-router-dom'; // Importez useNavigate

export const CashPayement = async (commandes, navigate) => { 
  try {
    const response = await paiement(commandes);
    if (response.ok) {
      const status = "success";
      localStorage.setItem('status', status);
      localStorage.setItem('sale_id', response.sale_id);
      navigate('/');  // Rediriger l'utilisateur
    } else {
      alert("Erreur lors du paiement");
    }
  } catch (error) {
    console.log("Erreur lors de l'envoi des données du paiement ", error);
  }
};

